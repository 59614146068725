import React from "react";
import { useNavigate, Link } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const uploadVideoNavigation = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <div>
      <nav className="main-header navbar navbar-expand-md navbar-light navbar-dark">
        <div className="container">
          <a href="#" className="navbar-brand" onClick={uploadVideoNavigation}>
            <span className="brand-text font-weight-light">
              <strong>split-video.yopyo</strong>
            </span>
          </a>
          <div className="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-bars"></i>
                </a>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={uploadVideoNavigation}
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    color: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <small>
                    <strong>Importation de la vidéo</strong>
                  </small>
                </button>
              </li>
            </ul>
          </div>
          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fas fa-chevron-circle-down fa-lg" />
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu">
                <span className="dropdown-header">Paramètres</span>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item">
                  <i className="fas fa-users mr-2" /> paramètre - 1
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item bg-danger dropdown-footer">
                  Effacer toutes les données
                </a>
              </div>
            </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fas fa-expand-arrows-alt fa-lg" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
