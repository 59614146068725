import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function SideNav({ reloadProjects }) {
  const navigate = useNavigate();
  const location = useLocation(); // Hook pour obtenir l'emplacement actuel

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null); // Projet sélectionné
  
  const API_URL = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_URL}/getAllProjectName.php`);
        const data = await response.json();
        if (data.error) {
          console.error(data.error);
        } else {
          setProjects(data.projects);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [API_URL, reloadProjects]);

  // Mettre à jour le projet sélectionné à partir de l'URL
  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const currentProject = pathParts[pathParts.length - 1];
    setSelectedProject(currentProject);
  }, [location.pathname]);

  const projectNavigation = (project) => {
    console.log(`/video-list/${project}`);
    navigate(`/video-list/${project}`);
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">Menu</div>
          </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item menu-is-opening menu-open">
                <a href="#" className="nav-link">
                  <p>
                    <strong>
                      <i className="nav-icon fas fa-project-diagram" />
                      Projets
                    </strong>
                    <i className="fas fa-angle-left right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  {projects.length > 0 ? (
                    projects.map((project) => (
                      <li className="nav-item" key={project}>
                        <a
                          href="#"
                          className={`nav-link ${
                            selectedProject === project ? "active" : ""
                          }`}
                          onClick={() => projectNavigation(project)}
                        >
                          <p>
                            <small>
                              <strong>
                                <i className="nav-icon fas fa-folder" />
                                {project}
                              </strong>
                            </small>
                          </p>
                        </a>
                      </li>
                    ))
                  ) : (
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <p>Aucun projet disponible</p>
                      </a>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default SideNav;
