// src/components/PageLayout.jsx
import React from "react";
import UploadVideo from "../pages/UploadVideo";
import Home from "../pages/Home";

function PageLayout({ onProjectCreated }) {
  // Reçois la prop onProjectCreated
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">{/* Here goes Header */}</div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <UploadVideo onProjectCreated={onProjectCreated} />{" "}
            {/* Passe la prop à UploadVideo */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default PageLayout;
