import React from "react";
import TableVideos from "../components/Tables/TableVideos";
import { useParams } from "react-router-dom";

const Home = () => {
  const { projectName } = useParams();

  const handleDownloadZip = () => {
    if (projectName) {
      // Crée l'URL pour télécharger le ZIP
      const zipUrl = `${process.env.REACT_APP_API_HOST}/downloadZipByProjectName.php?projectName=${projectName}`;
      // Ouvre l'URL dans un nouvel onglet pour déclencher le téléchargement
      window.open(zipUrl, "_blank");
    } else {
      console.error("Nom du projet manquant");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={handleDownloadZip}
            >
              <small>
                <strong>Scènes coupées (.zip)</strong>
              </small>
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <TableVideos projectName={projectName} />
        </div>
      </div>
    </div>
  );
};

export default Home;
