// src/App.jsx
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import PageLayout from "./components/PageLayout";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import Home from "./pages/Home";
import { useState } from "react"; // Import useState

function App() {
  const [reloadProjects, setReloadProjects] = useState(false); // Ajoute l'état pour le rechargement

  const handleProjectCreated = () => {
    setReloadProjects((prev) => !prev); // Inverse la valeur pour déclencher le rechargement
  };

  return (
    <Router>
      <div className="wrapper">
        <Header />
        <SideNav reloadProjects={reloadProjects} />{" "}
        {/* Passe reloadProjects comme prop */}
        <Routes>
          <Route
            path="/"
            element={<PageLayout onProjectCreated={handleProjectCreated} />}
          />{" "}
          {/* Passe handleProjectCreated */}
          <Route path="/video-list/:projectName" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
