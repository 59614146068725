import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const UploadVideo = ({ onProjectCreated }) => {

  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [fileName, setFileName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_HOST
  console.log(API_URL)
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : "");
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!fileName || !projectName) {
      setError(
        "Veuillez sélectionner un fichier vidéo et entrer le nom du projet."
      );
      return;
    }

    const formData = new FormData();
    formData.append("videoFile", e.target.videoFile.files[0]);
    formData.append("project", projectName);

    setLoading(true);
    setIsUploadComplete(false);
    setError(null);

    try {
      // 1. Upload video file
      const uploadResponse = await axios.post(
        `${API_URL}/upload.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (uploadResponse.data.status !== 200) {
        throw new Error(
          uploadResponse.data.message || "Erreur lors de l'upload"
        );
      }

      // 2. Process the uploaded video
      const cropResponse = await axios.post(`${API_URL}/cropVideo.php`, {
        projectName: projectName,
      });

      if (cropResponse.status !== 200) {
        throw new Error(
          cropResponse.data.message || "Erreur lors du traitement vidéo"
        );
      }

      setIsUploadComplete(true);

      // Signale au parent que le projet a été créé
      if (onProjectCreated) {
        onProjectCreated();
      }

      navigate(`/video-list/${projectName}`);
    } catch (error) {
      setError(error.message || "Erreur inattendue");
      console.error("Erreur:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setProgress(0);
    setLoading(false);
    setIsUploadComplete(false);
    setFileName("");
    setProjectName("");
    setError(null);
    document.getElementById("videoUploadForm").reset();
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">split-video.yopyo</h2>
      <div className="row">
        <div className="col-md-6 mx-auto pt-3">
          {loading ? (
            <div id="loading" className="mt-3 text-center">
              <div className="spinner-border" role="status"></div>
              <p>Processus en cours...</p>
            </div>
          ) : (
            <>
              {error && <div className="alert alert-danger">{error}</div>}
              <form id="videoUploadForm" onSubmit={handleUpload}>
                <div className="form-group">
                  <div className="input-group input-group-sm">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="videoFile"
                        name="videoFile"
                        accept="video/*"
                        onChange={handleFileChange}
                        required
                      />
                      <label className="custom-file-label" htmlFor="videoFile">
                        <small>
                          <strong>{fileName || "Choisissez le fichier"}</strong>
                        </small>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="project">
                    <small>
                      <strong>
                        Nom du projet&nbsp;
                        <span style={{ color: "yellow" }}>*&nbsp;</span>:
                      </strong>
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="project"
                    name="project"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    placeholder="Nom du projet"
                    required
                  />
                </div>
                <div className="progress mt-3 mb-3" style={{ height: "25px" }}>
                  <div
                    id="progressBar"
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm btn-flat w-100"
                  disabled={loading}
                >
                  <strong>{loading ? "Importation..." : "Importer"}</strong>
                </button>
              </form>
              {isUploadComplete && (
                <div className="alert alert-success mt-3">
                  Vidéo uploadée avec succès ! Fichier: {fileName}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;
